import React, { Component } from 'react';
import Login from './Components/Login/Login.jsx';

import { MuiThemeProvider as MaterialUI, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { connect } from 'react-redux';
import { setContextMenuVisible } from './Actions/Actions.js';


const theme = createMuiTheme({
    palette: {
        primary: blue,
    },
    typography: {
        useNextVariants: true,
    }
});

class App extends Component {

    render() {
        return (
            <MaterialUI theme={theme}>
                <div onClick={this.props.handleHideContextMenu} onContextMenu={this.props.handleHideContextMenu}>
                <Login />
                </div>
            </MaterialUI>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleHideContextMenu: (event) => {
            if (! (event.target.tagName === 'INPUT' || /label/i.test(event.target.className))) {
                event.preventDefault();
            }
            dispatch(setContextMenuVisible(false));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
