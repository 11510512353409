import React, { Component } from 'react';
import FileList from '../FileList/FileList.jsx';
import Navbar from '../Navbar/Navbar.jsx';
import ContextMenu from '../ContextMenu/ContextMenu.jsx';
import Dialogs from '../Dialogs/Dialogs.jsx';
import DynamicSnackbar from '../Notification/DynamicSnackbar.jsx'; 
import { login, isLogged } from '../../Api/ApiHandler.js';
import { refreshFileList } from '../../Actions/Actions.js';
import Recaptcha from 'react-google-invisible-recaptcha';
import { connect } from 'react-redux';
import "./Login.css";

class Login extends Component{
    constructor(props) {
        super(props);
        this.state = {username: '', password: '', loggedIn: false};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onResolved = this.onResolved.bind(this);
        this.checkIsLogged();
    }
    
    componentDidMount() {
        this.checkIsLogged();
    }

    handleChange(key) {
        return function (e) {
            var state = {};
            state[key] = e.target.value;
            this.setState(state);
        }.bind(this);
    }
    
    handleSubmit(event) {
        if ( '' === this.state.username || '' === this.state.password ) {
            alert( 'Validation failed! Credentials cannot be empty.' );
            this.recaptcha.reset();
        }else this.recaptcha.execute();
        event.preventDefault();
    }

    onResolved() {
        console.log("reponse captcha : ",this.recaptcha.getResponse());
        login(this.state.username, btoa(this.state.password)).then(r => {
            console.log("OK reponse : ", r);
            localStorage.setItem("username", r.id);
            localStorage.setItem("token", r.jwt);
            localStorage.setItem("auth", r.auth);
            this.setState({loggedIn: true});
            this.props.refresh();
        }).catch(r => {
            alert("Wrong Credential !");
            this.setState({loggedIn: false});
            //TODO if user wrong 5 times in a row
        })
        console.log("login request : ", this.state.username, btoa(this.state.password));
    }
    checkIsLogged(){
        let token = localStorage.getItem("token");
        console.log("token : ", token, token !== null);
        if(token !== undefined && token !== null && token !== ""){
            console.log("token : ", token);
            isLogged(token).then(r => {
                console.log(`user ${r.id} connected`);
                this.setState({loggedIn : true});
                this.props.refresh();
            });
        } 
    }
    

    render() {
        if(this.state.loggedIn){
            document.body.style.background = 'white';
            return(
                <div>
                    <Navbar />
                    <FileList />
                    <ContextMenu />
                    <DynamicSnackbar />
                    <Dialogs />
                </div>
            );
        }else{
            document.body.style.background = '#0c205f';
            return (
                <div className="login-page">
                    <div className="form">
                    <div className="connexion">Connexion</div>
                    <form className="login-form" onSubmit={this.handleSubmit}>
                        <input className="input" type="text" placeholder="username" value={this.state.username} onChange={this.handleChange('username')}/>
                        <input className="input" type="password" placeholder="password" value={this.state.password} onChange={this.handleChange('password')}/>
                        <input className="submit" type="submit" value="Login" />
                    </form>
                    </div>
                    <Recaptcha
                        ref = {ref => this.recaptcha = ref }
                        sitekey = "6LchtoYcAAAAAKcwPpigLqQYJhHErofeNQVLWeij"
                        onResolved = { this.onResolved }
                        onExpired = {e => console.log("captcha request expired", e)}
                        onError = {e => console.log("error in captcha : ", e)}
                    />
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        refresh: () => {
            dispatch(refreshFileList());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);